// src/pages/Products.js
import React from "react";
import "./styles/Products.css"; // Asegúrate de que la ruta coincida con la estructura de tu proyecto

function Products() {
  return (
    <div className="Products">
      <h1>Bienvenido a la página de inicio</h1>
      // Contenido adicional aquí
    </div>
  );
}

export default Products;
