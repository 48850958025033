import LogoFlukeCal from "../assets/logos/fluke-calibration.webp";
import LogoFlukeNetworks from "../assets/logos/fluke_networks.webp";
import LogoFlukeProcessInt from "../assets/logos/fluke-process-instruments.svg";
import LogoFlukeBiomedical from "../assets/logos/logo-flukebiomedical-white.svg";
import LogoFluke from "../assets/logos/fluke.svg";
import LogoGeokon from "../assets/logos/geokon-logo.png";
import LogoCampbell from "../assets/logos/logo_campbellcsi.png";
import LogoLifeloc from "../assets/logos/logo-lifeloc.png";
import LogoTesto from "../assets/logos/logo_testo.png";
import LogoHoneywell from "../assets/logos/logo_honeywell.svg";
import LogoPulsar from "../assets/logos/logo_pulsar_measurement.webp";
import LogoRaeSpain from "../assets/logos/logo_raespain.png";
import LogoCapelec from "../assets/logos/logo-capelec.webp";
import LogoSonlist from "../assets/logos/solinst-logo.webp";

const listBrands = [
  {
    id: 1,
    imgSrc: LogoFlukeCal,
    alt: "Fluke Calibration",
    url: "https://la.flukecal.com/",
  },
  {
    id: 2,
    imgSrc: LogoFlukeBiomedical,
    alt: "Fluke Biomedical",
    url: "https://www.flukebiomedical.com/",
  },
  {
    id: 3,
    imgSrc: LogoFlukeNetworks,
    alt: "Fluke Networks",
    url: "https://es.flukenetworks.com/",
  },
  {
    id: 4,
    imgSrc: LogoFlukeProcessInt,
    alt: "Fluke Process Instruments",
    url: "https://www.flukeprocessinstruments.com/",
  },
  {
    id: 5,
    imgSrc: LogoFluke,
    alt: "Fluke",
    url: "https://www.fluke.com/",
  },
  {
    id: 6,
    imgSrc: LogoGeokon,
    alt: "Geokon",
    url: "https://www.geokon.com/",
  },
  {
    id: 7,
    imgSrc: LogoCampbell,
    alt: "CAMPBELL SCIENTIFIC, INC.",
    url: "https://www.campbellsci.com/",
  },
  {
    id: 8,
    imgSrc: LogoLifeloc,
    alt: "Lifeloc",
    url: "https://lifeloc.com/",
  },
  {
    id: 9,
    imgSrc: LogoTesto,
    alt: "Testo SE",
    url: "https://www.testo.com/",
  },
  {
    id: 10,
    imgSrc: LogoHoneywell,
    alt: "Honeywell International Inc.",
    url: "https://www.honeywell.com/",
  },
  {
    id: 11,
    imgSrc: LogoPulsar,
    alt: "Pulsar Measurement",
    url: "https://pulsarmeasurement.com/",
  },
  {
    id: 12,
    imgSrc: LogoRaeSpain,
    alt: "RAE Spain",
    url: "https://www.raespain.com/",
  },
  {
    id: 13,
    imgSrc: LogoCapelec,
    alt: "Capelec",
    url: "https://www.capelec.com/",
  },
  {
    id: 14,
    imgSrc: LogoSonlist,
    alt: "Solinst",
    url: "https://www.solinst.com/",
  },
];
export default listBrands;
