import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./styles/Navbar.css";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const menuItems = {
    Inicio: "/",
    "Acerca de Nosotros": "/sobre-nosotros",
    Soluciones: "/soluciones",
    Servicios: "/servicios",
    "Noticias y Eventos": "/noticias",
    Contacto: "/contacto",
  };

  const toggleMenu = () => setIsOpen(!isOpen);
  const closeMenu = () => setIsOpen(false);

  return (
    <nav className="nav-menu fixed top-0 left-0 w-full z-20 bg-opacity-90 text-white">
      <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
        <div className="flex items-center justify-between py-1 sm:py-2">
          <Link to="/" className="flex items-center" onClick={closeMenu}>
            <img
              className="h-6 w-auto sm:h-7 md:h-9 lg:h-12" // Ajustado para aumentar el tamaño
              src="/assets/logos/logo.svg"
              alt="Logo"
            />
          </Link>
          <div className="hidden md:flex justify-end items-center flex-1">
            {Object.entries(menuItems).map(([label, path]) => (
              <Link
                key={label}
                to={path}
                className="px-2 py-1 rounded-md text-xs font-medium relative hover:bg-gray-700 after:absolute after:inset-x-0 after:bottom-0 after:h-0.5 after:bg-white after:scale-0 after:transition-transform after:duration-300 hover:after:scale-100"
                onClick={closeMenu}
              >
                {label}
              </Link>
            ))}
          </div>
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="p-2 rounded-md text-white hover:bg-gray-700"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1">
            {Object.entries(menuItems).map(([label, path]) => (
              <Link
                key={label}
                to={path}
                className="block px-2 py-1 rounded-md text-xs font-medium hover:bg-gray-700"
                onClick={closeMenu}
              >
                {label}
              </Link>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
