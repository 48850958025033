import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar"; // Asegúrate de importar Navbar correctamente
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Products from "./pages/Products";
import Services from "./pages/Services";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import ScrollToTopButton from "./components/ScrollToTopButton";
import Solutions from "./pages/Solutions";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Router>
      <Navbar />
      <ScrollToTop />
      <div style={{ paddingTop: "4rem" }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sobre-nosotros" element={<AboutUs />} />
          <Route path="/soluciones" element={<Solutions />} />
          <Route path="/contacto" element={<Contact />} />
          <Route path="/productos" element={<Products />} />
          <Route path="/servicios" element={<Services />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <ScrollToTopButton />
      <Footer />
    </Router>
  );
}

export default App;
