import React, { useState, useEffect } from "react";
import "./styles/Solutions.css";
import groupedSolutions from "./groupedSolutions";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

function Solutions() {
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    const hash = window.location.hash.replace("#", "");
    if (hash) {
      setSelectedTypes([hash]);
    }

    const handleHashChange = () => {
      const newHash = window.location.hash.replace("#", "");
      setSelectedTypes([newHash]);
    };

    window.addEventListener("hashchange", handleHashChange, false);

    return () => {
      window.removeEventListener("hashchange", handleHashChange, false);
    };
  }, []);

  useEffect(() => {
    const typesMatchingSearch = groupedSolutions
      .filter((group) =>
        group.type.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      )
      .map((group) => group.id);

    setSelectedTypes(typesMatchingSearch);
  }, [debouncedSearchTerm]);

  const handleCheckboxChange = (type) => {
    setSelectedTypes((prevSelectedTypes) => {
      if (prevSelectedTypes.includes(type)) {
        return prevSelectedTypes.filter(
          (selectedType) => selectedType !== type
        );
      } else {
        return [...prevSelectedTypes, type];
      }
    });
  };

  return (
    <div className="flex flex-col md:flex-row">
      <div className="filter-container z-10 bg-gray-800 p-4 md:h-screen md:w-1/4">
        <h3 className="font-bold mb-4 text-white">Filtrar por:</h3>
        <div className="mb-4 flex">
          <input
            type="text"
            placeholder="Buscar..."
            className="search-input p-2 w-full text-gray-900 bg-gray-300 rounded-md"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {searchTerm && (
            <button
              onClick={() => setSearchTerm("")}
              className="ml-2 bg-red-500 text-white p-2 rounded-md"
            >
              Limpiar
            </button>
          )}
        </div>

        {groupedSolutions.map((group, index) => (
          <div key={index} className="mb-2">
            <button
              className={`${
                selectedTypes.includes(group.id)
                  ? "bg-blue-500 text-white"
                  : "bg-gray-600 text-gray-200"
              } w-full px-4 py-2 rounded-md`}
              onClick={() => handleCheckboxChange(group.id)}
            >
              {group.type}
            </button>
          </div>
        ))}
      </div>

      <div className="flex-1 overflow-auto p-4">
        {groupedSolutions
          .filter((group) =>
            selectedTypes.length === 0 ? true : selectedTypes.includes(group.id)
          )
          .map((group, index) => (
            <div key={index} className="mb-10">
              <div className="py-5">
                <h2 className="text-3xl font-bold text-white">{group.type}</h2>
                <p className="text-lg text-gray-400">{group.description}</p>
              </div>
              {group.solutions.map((solution, solutionIndex) => (
                <div
                  key={solutionIndex}
                  className="relative overflow-hidden dark:border-gray-700 border-gray-600 dark:bg-gray-800 bg-gray-700 my-4 rounded-xl shadow-lg"
                >
                  <div className="px-4 py-8 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
                      <div className="lg:mt-6">
                        <img
                          src={solution.image}
                          alt={solution.title}
                          className="h-48 w-full sm:h-64 object-cover rounded-lg shadow-md"
                        />
                      </div>
                      <div>
                        <div className="flex items-center justify-between mb-4">
                          <h3 className="text-xl font-bold tracking-tight sm:text-2xl mt-4">
                            {solution.title}
                          </h3>
                        </div>
                        <p className="mt-4 text-lg text-gray-400">
                          {solution.description}
                        </p>
                        <div className="flex items-center mt-4">
                          {solution.brandLogo && (
                            <div
                              className="bg-white bg-opacity-15 p-1 rounded"
                              style={{ marginRight: "8px" }}
                            >
                              <img
                                src={solution.brandLogo}
                                alt={`${solution.brand} logo`}
                                className="h-10 object-cover"
                              />
                            </div>
                          )}
                        </div>
                        <div className="flex items-center mt-4">
                          <a
                            href={solution.url}
                            className="inline-block rounded-md border border-transparent bg-indigo-600 px-8 py-3 font-medium text-white hover:bg-indigo-700"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Más información
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
}

export default Solutions;
