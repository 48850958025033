import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const AboutUsSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="bg-gradient-to-br from-gray-700 to-gray-900 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-center items-center text-center mb-12 lg:text-left lg:justify-start">
          <div className="flex flex-col lg:flex-row items-center">
            <img
              src="https://via.placeholder.com/400"
              alt="TEC Industrial"
              className="w-full lg:w-1/3 object-cover rounded-lg shadow-xl transform transition duration-500 hover:scale-105 mx-auto mb-6 lg:mb-0 lg:mr-8"
              data-aos="fade-up"
              data-aos-delay="400"
              style={{ maxWidth: "400px", maxHeight: "400px" }}
            />
            <div>
              <h2 className="text-4xl font-semibold mb-4">Quiénes Somos</h2>
              <p className="text-lg leading-relaxed">
                En TEC Industrial, nos dedicamos a proveer soluciones integrales
                y de vanguardia en tecnología industrial e instrumentación,
                asegurando la máxima eficiencia y confiabilidad en sus
                operaciones.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-around gap-8 items-center">
          <div
            className="w-full md:w-1/2 lg:w-5/12 px-4"
            data-aos="fade-right"
            data-aos-delay="200"
          >
            <div className="px-6 py-8 shadow-lg rounded-lg bg-gray-800">
              <h3 className="text-3xl font-semibold mb-4">Misión</h3>
              <p className="text-lg leading-relaxed">
                En TEC Industrial, nuestra misión es proveer soluciones
                integrales y de vanguardia en tecnología industrial e
                instrumentación, asegurando a nuestros clientes la máxima
                eficiencia y confiabilidad en sus operaciones.
              </p>
            </div>
          </div>
          <div
            className="w-full md:w-1/2 lg:w-5/12 px-4"
            data-aos="fade-left"
            data-aos-delay="600"
          >
            <div className="px-6 py-8 shadow-lg rounded-lg bg-gray-800">
              <h3 className="text-3xl font-semibold mb-4">Visión</h3>
              <p className="text-lg leading-relaxed">
                Aspiramos a ser líderes en el mercado de tecnología industrial,
                reconocidos por nuestra innovación, calidad y servicio
                excepcional, expandiendo nuestras fronteras a nivel
                internacional.
              </p>
            </div>
          </div>
        </div>
        <div
          className="text-center mt-12"
          data-aos="fade-up"
          data-aos-delay="800"
        >
          <Link
            to="/sobre-nosotros"
            className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
            type="button"
          >
            Leer Más
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;
