import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
// Definición de los productos como un array para facilitar su manejo
const products = [
  {
    id: 1,
    name: "Multímetros Digitales Fluke",
    description:
      "Solución esencial para profesionales de la electricidad, ofreciendo mediciones precisas de voltaje, corriente y resistencia, garantizando la máxima precisión en entornos industriales.",
    imageUrl: "https://via.placeholder.com/350x250",
    moreInfoUrl: "#ver-mas-productos",
  },
  {
    id: 2,
    name: "Cámaras Termográficas Fluke",
    description:
      "Herramientas imprescindibles para la inspección y el mantenimiento predictivo, permitiendo visualizar y medir diferencias de temperatura sin contacto directo, ideales para evaluar sistemas eléctricos y mecánicos.",
    imageUrl: "https://via.placeholder.com/350x250",
    moreInfoUrl: "#ver-mas-productos",
  },
  {
    id: 3,
    name: "Pinzas Amperimétricas Fluke",
    description:
      "Diseñadas para medir la corriente en un conductor sin necesidad de desconectarlo, ideales para trabajos en espacios reducidos y para técnicos que requieren mediciones eficientes y seguras.",
    imageUrl: "https://via.placeholder.com/350x250",
    moreInfoUrl: "#ver-mas-productos",
  },
  {
    id: 4,
    name: "Medidores de Resistencia de Aislamiento Fluke",
    description:
      "Cruciales para garantizar la seguridad y eficiencia de instalaciones eléctricas, previniendo fallos eléctricos y prolongando la vida útil de equipos y sistemas con mediciones de aislamiento precisas.",
    imageUrl: "https://via.placeholder.com/350x250",
    moreInfoUrl: "#ver-mas-productos",
  },
  {
    id: 5,
    name: "Paneles Solares de Alta Eficiencia",
    description:
      "Ofrecemos sistemas solares de última generación, desde pico sistemas hasta sistemas domiciliarios completos, proporcionando soluciones sostenibles y eficientes para la generación de energía renovable.",
    imageUrl: "https://via.placeholder.com/350x250",
    moreInfoUrl: "#ver-mas-productos",
  },
  {
    id: 6,
    name: "Inversores Solares",
    description:
      "Convierten la energía solar en electricidad utilizable para su hogar o negocio, maximizando la eficiencia de los sistemas fotovoltaicos con tecnología avanzada para un rendimiento óptimo.",
    imageUrl: "https://via.placeholder.com/350x250",
    moreInfoUrl: "#ver-mas-productos",
  },
  // Puedes agregar más productos relacionados con la Seguridad Industrial o cualquier otra categoría relevante.
];

const OurProductsSection = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    AOS.init(); // Inicializa AOS
    // Simula una carga asíncrona, por ejemplo, la obtención de productos desde una API.
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 1000); // Simula un retraso de 1 segundo antes de marcar como cargado

    return () => clearTimeout(timer); // Limpieza al desmontar
  }, []);

  if (!isLoaded) {
    return <div className="text-center text-white">Cargando productos...</div>; // Muestra un mensaje de carga o un spinner
  }

  return (
    <div className="py-8">
      <h2 className="text-3xl font-bold text-center text-white mb-6">
        Nuestros Productos
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {products.map((product, index) => (
          <div
            key={product.id}
            data-aos="fade-up"
            data-aos-delay={`${index * 100}`}
            data-aos-duration="1000"
            className="bg-gray-800 p-4 rounded-lg hover:scale-105 transition-transform duration-300 ease-in-out shadow-lg"
          >
            <img
              src={product.imageUrl}
              alt={product.name}
              className="mb-3 rounded w-full h-64 object-cover"
            />
            <h3 className="text-xl font-semibold mb-2 text-white">
              {product.name}
            </h3>
            <p className="mb-4 text-gray-300">{product.description}</p>
            <a
              href={product.moreInfoUrl}
              className="underline text-blue-400 hover:text-blue-300"
            >
              Ver más
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurProductsSection;
