import React from "react";

const ServicesSection = () => {
  const services = [
    {
      id: 1,
      title: "Instalación de Estaciones Meteorológicas",
      description:
        "Implementación de sistemas de monitoreo climático con equipos de Campbell Scientific y Geokon, asegurando mediciones precisas y confiables.",
      imageUrl: "https://via.placeholder.com/350x250",
    },
    {
      id: 2,
      title: "Asistencia en Proyectos de Presas Hidroeléctricas",
      description:
        "Consultoría y supervisión en la instalación de instrumentos geotécnicos, ofreciendo asistencia integral en construcciones hidroeléctricas.",
      imageUrl: "https://via.placeholder.com/350x250",
    },
    {
      id: 3,
      title: "Calibración de Alcoholímetros Lifeloc",
      description:
        "Servicio profesional de calibración para alcoholímetros Lifeloc, con técnicos especializados asegurando precisión y confiabilidad.",
      imageUrl: "https://via.placeholder.com/350x250",
    },
  ];

  return (
    <div className="bg-gray-900 text-white py-12">
      <div className="max-w-screen-xl mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12">
          Servicios Especializados
        </h2>
        {services.map((service) => (
          <div
            key={service.id}
            className="flex flex-col md:flex-row items-center md:justify-between bg-gray-800 mb-8 p-6 rounded-lg"
          >
            <img
              src={service.imageUrl}
              alt={service.title}
              className="w-full md:w-1/4 h-auto object-cover rounded-md mb-4 md:mb-0 md:mr-4"
            />
            <div>
              <h3 className="text-2xl font-semibold mb-2">{service.title}</h3>
              <p className="text-gray-300 mb-4">{service.description}</p>
              <a
                href={service.moreInfoUrl}
                className="text-blue-400 hover:text-blue-300 transition-colors duration-300"
              >
                Ver más
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesSection;
