import React from "react";
import PanelSolar from "../assets/images/panel_solar.jpg";
// Asegúrate de importar la imagen industrial correctamente si la vas a utilizar desde assets
// import IndustrialImage from "../assets/images/industrial-image.jpg";

function AboutUs() {
  return (
    <div className="max-w-screen-xl mx-auto px-4 py-8 bg-gray-900 text-gray-200">
      <section className="text-center mb-12 bg-gray-800 text-white py-12 rounded-lg shadow">
        <h1 className="text-4xl font-bold mb-4">Sobre nosotros</h1>
        <p className="text-xl font-semibold">
          Más de tres décadas entregando soluciones industriales al mundo.
        </p>
      </section>

      <section className="mb-12 bg-gray-700 py-8 rounded-lg shadow-lg">
        <div className="flex flex-wrap justify-around gap-8 items-center">
          <div className="w-full md:w-1/2 lg:w-5/12 px-4">
            <div className="px-6 py-8 shadow-lg rounded-lg bg-gray-800 h-full">
              <h3 className="text-3xl font-semibold mb-4">Misión</h3>
              <p className="text-lg leading-relaxed">
                En TEC Industrial, nuestra misión es proveer soluciones
                integrales y de vanguardia en tecnología industrial e
                instrumentación, asegurando a nuestros clientes la máxima
                eficiencia y confiabilidad en sus operaciones.
              </p>
            </div>
          </div>

          <div className="w-full md:w-1/2 lg:w-5/12 px-4">
            <div className="px-6 py-8 shadow-lg rounded-lg bg-gray-800 h-full">
              <h3 className="text-3xl font-semibold mb-4">Visión</h3>
              <p className="text-lg leading-relaxed">
                Aspiramos a ser líderes en el mercado de tecnología industrial,
                reconocidos por nuestra innovación, calidad y servicio
                excepcional, expandiendo nuestras fronteras a nivel
                internacional.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-12">
        <div className="flex flex-wrap justify-center items-center">
          <img
            src={PanelSolar}
            alt="Innovación en Tec Industrial"
            className="w-full sm:w-3/4 md:w-1/2 lg:w-2/5 xl:w-1/3 h-auto rounded-lg shadow-lg mb-8 md:mb-0 md:mr-8"
          />
          <p className="w-full sm:w-3/4 md:w-1/2 lg:w-2/5 xl:w-1/3 text-base md:text-lg leading-relaxed text-center md:text-left">
            Tec Industrial inició su viaje en 1990, concentrándose en proveer
            equipos y soluciones de alta calidad en instrumentación y medición
            industrial. Con una visión clara, nos hemos expandido globalmente,
            manteniendo un equipo de trabajo integrado que abarca varias
            regiones, con un fuerte enfoque en la innovación y la tecnología.
          </p>
        </div>
      </section>

      <section className="mb-12 text-gray-400">
        <h2 className="text-3xl font-bold text-gray-300 mb-6 text-center md:text-left">
          ¿Qué hacemos?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <img
            src={PanelSolar}
            alt="Soluciones Tec Industrial"
            className="rounded-lg shadow-lg mb-8 md:mb-0"
          />
          <div>
            <p className="text-base md:text-lg leading-relaxed">
              Desde nuestro lanzamiento, nos hemos especializado en ser el
              proveedor líder de tecnología innovadora, con el desarrollo de
              soluciones avanzadas para una amplia gama de aplicaciones
              industriales.
            </p>
            <ul className="list-disc ml-5 mt-4 space-y-2 text-base md:text-lg">
              <li>
                Introducción de tecnologías avanzadas de medición y control.
              </li>
              <li>
                Desarrollo de soluciones personalizadas para satisfacer las
                necesidades específicas de nuestros clientes.
              </li>
              <li>
                Expansión de servicios para incluir soporte técnico
                especializado.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
