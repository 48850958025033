import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const contributions = [
  {
    id: "industrial-instrumentation",
    title: "Instrumentación Industrial",
    description:
      "Proporcionamos soluciones avanzadas en instrumentación y medición, destacando en áreas como calibración, diagnóstico eléctrico y termografía, representando a Fluke, Testo, y Honeywell.",
    bgImage: "https://dam-assets.fluke.com/s3fs-public/ii900-1170x1084.jpg",
  },
  {
    id: "solar-energy",
    title: "Energía Solar",
    description:
      "Impulsamos la transición energética con sistemas solares eficientes, desde pico sistemas hasta soluciones domiciliarias integradas.",
    bgImage:
      "https://descubrelaenergia.fundaciondescubre.es/files/2021/10/energia-solar-650x432.jpg",
  },
  {
    id: "industrial-safety",
    title: "Seguridad Industrial",
    description:
      "Equipamiento de vanguardia para la seguridad en el trabajo, incluyendo monitores de gases, dosímetros de ruido, y sistemas de detección de flamas.",
    bgImage: "https://img.interempresas.net/FotosArtProductos/P131648.jpg",
  },
  {
    id: "vehicle-inspection",
    title: "Inspección Técnica Vehicular",
    description:
      "Soluciones integrales para la inspección vehicular, garantizando el cumplimiento y la seguridad con tecnología de punta.",
    bgImage:
      "https://psgautomotriz.com/781-large_default/regloscopio-cap-2600-experto-talleres-capelec.jpg",
  },
];

const ContributionsSection = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="flex flex-col gap-8 bg-gray-800 text-white p-8">
      {contributions.map((contribution, index) => (
        <div
          key={contribution.id}
          className="contribution-card flex flex-wrap justify-between items-center w-full rounded-lg overflow-hidden relative"
          data-aos={index % 2 === 0 ? "fade-right" : "fade-left"} // Alterna la dirección de la animación
          style={{
            backgroundImage: `linear-gradient(to ${
              index % 2 === 0 ? "right" : "left"
            }, rgba(0, 0, 0, 0.7) 0%, transparent 100%), url(${
              contribution.bgImage
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div
            className={`text-content w-full md:w-1/2 p-4 ${
              index % 2 === 0 ? "md:order-1" : "md:order-2"
            }`}
          >
            <h3 className="text-2xl font-semibold mb-4">
              {contribution.title}
            </h3>
            <p>{contribution.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContributionsSection;
