import React from "react";
import "./styles/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bg-gray-800 text-white p-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between items-center mb-6">
          <Link to="/" className="flex items-center">
            <img
              className="h-12 w-auto mr-3" // Ajuste para visualización del logo
              src="/assets/logos/logo.svg"
              alt="Logo TEC Industrial"
            />
          </Link>
          <div className="flex space-x-4">
            {/* Iconos de redes sociales */}
            <a href="https://facebook.com" className="hover:text-gray-400">
              <FontAwesomeIcon icon={faFacebookF} size="lg" />
            </a>
            <a href="https://instagram.com" className="hover:text-gray-400">
              <FontAwesomeIcon icon={faInstagram} size="lg" />
            </a>
            <a href="https://linkedin.com" className="hover:text-gray-400">
              <FontAwesomeIcon icon={faLinkedinIn} size="lg" />
            </a>
            <a href="https://wa.me/1234567890" className="hover:text-gray-400">
              <FontAwesomeIcon icon={faWhatsapp} size="lg" />
            </a>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="font-bold text-lg mb-2">Menú</h3>
            <ul className="text-sm">
              <li>
                <Link
                  to="/"
                  className="hover:text-gray-400 transition duration-300"
                >
                  Inicio
                </Link>
              </li>
              <li>
                <Link
                  to="/soluciones"
                  className="hover:text-gray-400 transition duration-300"
                >
                  Soluciones
                </Link>
              </li>
              <li>
                <Link
                  to="/servicios"
                  className="hover:text-gray-400 transition duration-300"
                >
                  Servicios
                </Link>
              </li>
              <li>
                <Link
                  to="/sobre-nosotros"
                  className="hover:text-gray-400 transition duration-300"
                >
                  Sobre Nosotros
                </Link>
              </li>
              <li>
                <Link
                  to="/sala-de-noticias"
                  className="hover:text-gray-400 transition duration-300"
                >
                  Sala de Noticias
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="font-bold text-lg mb-2">Recursos</h3>
            <ul className="text-sm">
              <li className="hover:text-gray-400 transition duration-300 mb-1">
                Foro de Usuarios
              </li>
              <li className="hover:text-gray-400 transition duration-300 mb-1">
                Videos y Tutoriales
              </li>
            </ul>
          </div>
          <div>
            <h3 className="font-bold text-lg mb-2">Contacto</h3>
            <p className="text-sm">
              Av. Blanco Galingo Km 3.8, c. Iporre Salinas #513, Cochabamba,
              Bolivia
            </p>
            <p className="text-sm">8:00 AM - 17 PM (Lunes - Viernes)</p>
            <p className="text-sm">4-4400917 | info@tec-industrial.com</p>
          </div>
        </div>
        <div className="text-center text-sm mt-6">
          <p>
            &copy; {new Date().getFullYear()} TEC Industrial. Todos los derechos
            reservados.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
