import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-900 text-white">
      <div className="bg-gray-800 p-8 rounded-lg shadow-md">
        <h2 className="text-4xl font-bold mb-4">Página no encontrada</h2>
        <p className="text-lg mb-8">
          Lo sentimos, la página que estás buscando no existe.
        </p>

        <Link
          to="/"
          className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
        >
          Regresar al inicio
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
