import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import listBrands from "../data/listBrands";
const BrandsSlider = () => {
  const settings = {
    dots: false, // Oculta los puntos de navegación
    infinite: true, // Hace que el carrusel sea infinito
    speed: 5000, // Tiempo en milisegundos que toma pasar de un slide a otro
    slidesToShow: 4, // Cantidad de slides a mostrar
    slidesToScroll: 1, // Cantidad de slides a desplazar
    autoplay: true, // Activa el autoplay
    autoplaySpeed: 0, // Hace que el carrusel se mueva de manera continua sin pausas
    cssEase: "linear", // Efecto de transición lineal para un movimiento suave y constante
    arrows: false, // Oculta las flechas de navegación
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="container mx-auto px-4">
      <div className="max-w-screen-xl mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12">
          Nuestras Marcas
        </h2>
        <Slider {...settings}>
          {listBrands.map((brand) => (
            // Ajusta el contenedor de cada logo para asegurar que esté centrado
            <div
              key={brand.id}
              className="flex justify-center items-center px-2"
            >
              <a href={brand.url} target="_blank" rel="noopener noreferrer">
                <img
                  src={brand.imgSrc}
                  alt={brand.alt}
                  style={{ width: "130px", height: "auto" }} // Ajusta el tamaño
                  className="transition-transform duration-300 hover:scale-110" // Efecto de escala al hacer hover
                />
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default BrandsSlider;
