// src/pages/Contact.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

function Contact() {
  return (
    <div className="bg-gray-900 text-white min-h-screen flex flex-col items-center pt-8 px-4">
      <h1 className="text-3xl md:text-4xl font-bold mb-8">Contáctanos</h1>

      <div className="w-full max-w-xs md:max-w-4xl lg:max-w-7xl p-4 md:p-8 bg-gray-800 rounded-lg shadow-md flex flex-col md:flex-row justify-between gap-8">
        {/* Formulario de contacto en una columna */}
        <div className="flex-1">
          <form className="grid grid-cols-1 gap-4">
            <label className="block">
              <span className="text-gray-300">Nombre completo</span>
              <input
                type="text"
                className="mt-1 block w-full rounded-md bg-gray-700 border-transparent focus:border-gray-500 focus:bg-gray-700 focus:ring-0 text-white"
                placeholder="Juan Pérez"
              />
            </label>
            <label className="block">
              <span className="text-gray-300">Correo electrónico</span>
              <input
                type="email"
                className="mt-1 block w-full rounded-md bg-gray-700 border-transparent focus:border-gray-500 focus:bg-gray-700 focus:ring-0 text-white"
                placeholder="juan@example.com"
              />
            </label>
            <label className="block">
              <span className="text-gray-300">Mensaje</span>
              <textarea
                className="mt-1 block w-full rounded-md bg-gray-700 border-transparent focus:border-gray-500 focus:bg-gray-700 focus:ring-0 text-white"
                rows="3"
                placeholder="Escribe tu mensaje aquí"
              ></textarea>
            </label>
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Enviar
            </button>
          </form>
        </div>

        {/* Información de contacto en la otra columna */}
        <div className="flex-1 space-y-4">
          <h3 className="font-bold text-lg mb-2">Síguenos</h3>
          <div className="flex justify-start">
            {/* Ejemplo de uso de FontAwesomeIcon con enlaces externos */}
            <a
              href="https://facebook.com"
              className="hover:text-gray-400 transition duration-300 mx-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebookF} size="lg" />
            </a>

            <a
              href="https://linkedin.com"
              className="hover:text-gray-400 transition duration-300 mx-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedinIn} size="lg" />
            </a>
            <a
              href="https://wa.me/1234567890"
              className="hover:text-gray-400 transition duration-300 mx-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faWhatsapp} size="lg" />
            </a>
          </div>
          {/* Información de contacto */}
          <h3 className="font-bold text-lg mb-2">Nuestra Dirección</h3>
          <p>
            Av. Blanco Galingo Km 3.8, c. Iporre Salinas #513, Cochabamba,
            Bolivia
          </p>
          <p>8:00 AM - 17 PM (Lunes - Viernes)</p>
          <p>4-4400917 | info@tec-industrial.com</p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
