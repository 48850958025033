import React from "react";
import "./styles/Home.css";
import BrandsSlider from "../components/BrandsSlider";
import ContributionsSection from "../components/ContributionsSection";
import OurProductsSection from "../components/OurProductsSection";
import AboutUsSection from "../components/AboutUsSection";

import Header from "../components/Header";
import ServicesSection from "../components/ServicesSection";

function Home() {
  return (
    <>
      <Header />
      <div className="home dark:bg-gray-900 dark:text-gray-100 min-h-screen">
        <div className="container mx-auto px-4 py-12 space-y-12">
          <AboutUsSection />
          <div className="text-center">
            <OurProductsSection />
          </div>
          <div>
            <ServicesSection />
          </div>
          {/* <div>
            <h2 className="text-2xl font-bold mb-4">NUESTRAS CONTRIBUCIONES</h2>
            <ContributionsSection />
          </div> */}
          <div>
            <BrandsSlider />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
