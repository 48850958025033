import React from "react";

function Services() {
  // Suponiendo que ya tienes definido el arreglo services en alguna parte
  const services = [
    {
      name: "Instalación de Estaciones Meteorológicas",
      images: [
        // Las URLs de las imágenes deben ser actualizadas según las imágenes reales disponibles
        {
          src: "https://example.com/estacion-meteorologica-image1.jpg",
          alt: "Estación meteorológica instalada",
        },
        {
          src: "https://example.com/estacion-meteorologica-image2.jpg",
          alt: "Técnicos trabajando en la instalación",
        },
        {
          src: "https://example.com/estacion-meteorologica-image3.jpg",
          alt: "Equipos y herramientas utilizadas",
        },
      ],
      description:
        "Ofrecemos soluciones integrales para la instalación de estaciones meteorológicas, asegurando mediciones precisas y confiables para el monitoreo climático.",
      highlights: [
        "Instalación por técnicos certificados",
        "Equipos de última generación de Campbell Scientific y Geokon",
      ],
      details:
        "Nuestras estaciones meteorológicas están equipadas con tecnología avanzada para garantizar la precisión en la medición de datos climáticos, esenciales para la investigación y la planificación agrícola.",
    },
    {
      name: "Asistencia en Proyectos de Presas Hidroeléctricas",
      images: [
        // Actualizar con imágenes reales de proyectos de presas hidroeléctricas
        {
          src: "https://example.com/presa-hidroelectrica-image1.jpg",
          alt: "Vista de la construcción de una presa",
        },
        {
          src: "https://example.com/presa-hidroelectrica-image2.jpg",
          alt: "Equipo técnico inspeccionando el sitio",
        },
        {
          src: "https://example.com/presa-hidroelectrica-image3.jpg",
          alt: "Maquinaria pesada en operación",
        },
      ],
      description:
        "Proporcionamos asistencia integral en la instalación de instrumentos geotécnicos y monitoreo de datos para presas hidroeléctricas.",
      highlights: [
        "Consultoría y diseño ingenieril especializado",
        "Supervisión de construcción y cumplimiento de normativas de seguridad",
      ],
      details:
        "Nuestro equipo de ingenieros y técnicos especializados ofrece soporte completo en el desarrollo de proyectos hidroeléctricos, asegurando su eficiencia, seguridad y sostenibilidad ambiental.",
    },
    {
      name: "Calibración de Alcoholímetros Lifeloc",
      images: [
        // Imágenes específicas del proceso de calibración de alcoholímetros Lifeloc
        {
          src: "https://example.com/alcoholimetro-lifeloc-image1.jpg",
          alt: "Alcoholímetro Lifeloc en proceso de calibración",
        },
        {
          src: "https://example.com/alcoholimetro-lifeloc-image2.jpg",
          alt: "Técnico especializado ajustando alcoholímetro",
        },
        {
          src: "https://example.com/alcoholimetro-lifeloc-image3.jpg",
          alt: "Certificación de calibración Lifeloc",
        },
      ],
      description:
        "Ofrecemos un servicio profesional de calibración para alcoholímetros Lifeloc, asegurando mediciones precisas y confiables.",
      highlights: [
        "Calibración certificada por técnicos especializados",
        "Proceso conforme a normativas internacionales",
      ],
      details:
        "Nuestro servicio incluye diagnóstico completo, ajuste preciso y certificación de calibración, garantizando el cumplimiento de los estándares de seguridad y precisión.",
    },
    // Puedes añadir más servicios aquí...
  ];

  return (
    <div className="bg-gray-900 text-white">
      <div className="pt-6">
        {services.map((service, index) => (
          <div key={index} className="py-10">
            {/* Breadcrumb y título */}
            <div className="px-4 sm:px-6 lg:px-8">
              <nav aria-label="Breadcrumb">
                <ol role="list" className="mx-auto flex items-center space-x-2">
                  <li className="text-sm text-gray-400">{service.name}</li>
                </ol>
              </nav>
              <h1 className="text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">
                {service.name}
              </h1>
            </div>

            {/* Galería de imágenes */}
            <div className="mx-auto mt-6 max-w-4xl sm:px-6 lg:max-w-7xl lg:grid lg:grid-cols-1 lg:gap-x-8 lg:px-8 lg:grid-cols-3">
              {service.images.map((image, idx) => (
                <div
                  key={idx}
                  className={`overflow-hidden rounded-lg shadow-lg ${
                    idx === 0
                      ? "lg:col-span-2 aspect-w-3 aspect-h-2"
                      : "aspect-w-1 aspect-h-1"
                  }`}
                >
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
              ))}
            </div>

            {/* Descripción y detalles */}
            <div className="mx-auto max-w-4xl px-4 pb-16 pt-10 sm:px-6 lg:max-w-7xl lg:px-8">
              <p className="mt-4 text-lg text-gray-400">
                {service.description}
              </p>
              <div className="mt-6">
                <ul
                  role="list"
                  className="list-disc space-y-2 pl-4 text-base text-gray-400"
                >
                  {service.highlights.map((highlight, idx) => (
                    <li key={idx} className="text-gray-300">
                      {highlight}
                    </li>
                  ))}
                </ul>
              </div>
              <p className="mt-4 text-base text-gray-600">{service.details}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
