import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import MediaQuery, { useMediaQuery } from "react-responsive";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";
import headerSlides from "../data/HeaderSlides";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import AOS from "aos";

const slides = headerSlides;

function Header() {
  const [activeSlide, setActiveSlide] = useState(0);
  const [autoSlide, setAutoSlide] = useState(true);
  const touchStartX = useRef(null);
  const touchMoveX = useRef(null);
  const intervalRef = useRef(null); // Declare intervalRef using useRef

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    let interval;
    if (autoSlide) {
      interval = setInterval(() => {
        goToNextSlide();
      }, 5000);
    }
    intervalRef.current = interval; // Set intervalRef.current to the created interval
    return () => clearInterval(intervalRef.current);
  }, [autoSlide, slides.length]);

  const handleMouseEnter = (index) => {
    setAutoSlide(false);
    setActiveSlide(index);
    forceAOSUpdate();
  };

  const handleMouseLeave = () => {
    setAutoSlide(true);
  };

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
    touchMoveX.current = touchStartX.current;
  };

  const handleTouchMove = (e) => {
    touchMoveX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const touchThreshold = 50;

    if (touchStartX.current - touchMoveX.current > touchThreshold) {
      goToNextSlide();
    } else if (touchMoveX.current - touchStartX.current > touchThreshold) {
      goToPrevSlide();
    }

    touchStartX.current = null;
    touchMoveX.current = null;
  };
  const goToNextSlide = () => {
    setAutoSlide(false);
    clearInterval(intervalRef.current);
    setActiveSlide((prev) => (prev + 1) % slides.length);
    forceAOSUpdate();
    intervalRef.current = setInterval(() => {
      setActiveSlide((prev) => (prev + 1) % slides.length);
      forceAOSUpdate();
    }, 5000);
  };

  const goToPrevSlide = () => {
    setAutoSlide(false);
    clearInterval(intervalRef.current);
    setActiveSlide((prev) => (prev - 1 + slides.length) % slides.length);
    forceAOSUpdate();
    intervalRef.current = setInterval(() => {
      setActiveSlide((prev) => (prev + 1) % slides.length);
      forceAOSUpdate();
    }, 5000);
  };

  const forceAOSUpdate = () => {
    setTimeout(() => {
      AOS.refresh();
    }, 100);
  };

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <div
      className="relative w-full overflow-hidden"
      style={{ height: "calc(100vh - 4rem)" }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`${
            activeSlide === index ? "block" : "hidden"
          } absolute inset-0`}
          data-aos={
            index === activeSlide
              ? "fade"
              : index < activeSlide
              ? "fade-right"
              : "fade-left"
          }
        >
          <img
            src={slide.src}
            alt={slide.alt}
            className="w-full h-full object-cover"
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          />
          <div
            className={`absolute ${
              isMobile ? "bottom-0" : "bottom-1/4"
            } left-0 right-0 text-center text-white bg-black bg-opacity-70 p-4`}
          >
            <h6 className="text-2xl md:text-4xl font-bold mb-2">
              {slide.title}
            </h6>
            <p className="text-sm md:text-base leading-relaxed">
              {slide.description}
            </p>
            <Link
              to={`/soluciones`}
              className="text-white mt-2 inline-block underline"
            >
              Ver Más
            </Link>
          </div>
        </div>
      ))}

      <MediaQuery minWidth={768}>
        {(matches) => (
          <>
            {matches && (
              <div
                className="absolute bottom-0 left-0 right-0 flex items-center bg-black bg-opacity-75 overflow-hidden"
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
              >
                <div className="flex w-full justify-center gap-1 p-2">
                  <button onClick={goToPrevSlide} className="text-white mr-2">
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                  {slides.map((slide, index) => (
                    <Link
                      to="/soluciones"
                      key={index}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      style={{ width: `calc(100% / ${slides.length} - 8px)` }}
                    >
                      <div
                        className={`flex-none transition duration-300 ease-in-out transform hover:scale-105 ${
                          activeSlide === index
                            ? "ring-2 ring-white"
                            : "ring-1 ring-gray-700"
                        }`}
                      >
                        <img
                          src={slide.src}
                          alt={slide.title}
                          className="w-full h-16 md:h-20 object-cover rounded-md"
                        />
                        <div
                          className="bottom-0 w-full text-center text-white uppercase p-1 bg-black bg-opacity-50 leading-tight overflow-hidden"
                          style={{ fontSize: "0.65rem" }}
                        >
                          {slide.title}
                        </div>
                      </div>
                    </Link>
                  ))}
                  <button onClick={goToNextSlide} className="text-white ml-2">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </MediaQuery>
    </div>
  );
}

export default Header;
