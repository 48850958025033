const headerSlides = [
  {
    src: "https://www.infoplc.net/media/k2/items/cache/59686f9350ffec797d31a170eb5ee32d_XL.jpg",
    alt: "Instrumentación Industrial",
    title: "Instrumentación Industrial",
    description:
      "Explora las herramientas y sistemas avanzados utilizados en la instrumentación industrial para mejorar la eficiencia y seguridad en la producción.",
  },
  {
    src: "https://cdn.www.gob.pe/uploads/document/file/2656778/standard_WhatsApp%20Image%202021-12-28%20at%2010.58.35%20AM.jpeg.jpeg",
    alt: "Meteorología e Hidrología",
    title: "Meteorología e Hidrología",
    description:
      "Explora el impacto y la importancia de la meteorología y la hidrología en la predicción del tiempo y el estudio de los recursos hídricos.",
  },
  {
    src: "https://ingeoexpert.com/wp-content/uploads/2019/04/Slope.jpg",
    alt: "Instrumentación Geotécnica",
    title: "Instrumentación Geotécnica",
    description:
      "Descubre cómo la instrumentación geotécnica es crucial para el análisis del comportamiento del suelo y las estructuras de soporte.",
  },
  {
    src: "https://b3392442.smushcdn.com/3392442/wp-content/uploads/2022/03/fluke-ii900-camara-acustica.jpg?lossy=1&strip=1&webp=1",
    alt: "Seguridad Industrial",
    title: "Seguridad Industrial",
    description:
      "Aprende sobre las últimas tecnologías y prácticas en seguridad industrial para prevenir accidentes y proteger a los trabajadores.",
  },
  {
    src: "https://2.bp.blogspot.com/-NA5S5R4bWu4/V3GRV47j62I/AAAAAAAAA_k/IJ6bKezcbiY6FW8zfenrimOvAadw_fcaACLcB/s1600/estructuras-metalicas-cubiertas-placas-solares-ingenieria-naves-metalicas-industriales-ganaderas-agricolas-cerramientos-metalicos-sobrecubierta-metalica-chapa-placas%2Bsolares.jpg",
    alt: "Energía Solar",
    title: "Energía Solar",
    description:
      "Adéntrate en el mundo de la energía solar, su funcionamiento, beneficios y el futuro de la energía renovable.",
  },
  {
    src: "https://2.bp.blogspot.com/-NA5S5R4bWu4/V3GRV47j62I/AAAAAAAAA_k/IJ6bKezcbiY6FW8zfenrimOvAadw_fcaACLcB/s1600/estructuras-metalicas-cubiertas-placas-solares-ingenieria-naves-metalicas-industriales-ganaderas-agricolas-cerramientos-metalicos-sobrecubierta-metalica-chapa-placas%2Bsolares.jpg",
    alt: "Inspección Técnica Vehicular",
    title: "Inspección Técnica Vehicular",
    description:
      "Adéntrate en el mundo de la energía solar, su funcionamiento, beneficios y el futuro de la energía renovable.",
  },
];
export default headerSlides;
