import ImgWeather from "../assets/images/monitoreo-meteorologico.png";

import LogoFlukeCal from "../assets/logos/fluke-calibration.webp";
import LogoFlukeNetworks from "../assets/logos/fluke_networks.webp";
import LogoFlukeProcessInt from "../assets/logos/fluke-process-instruments.svg";
import LogoFlukeBiomedical from "../assets/logos/logo-flukebiomedical-white.svg";
import LogoFluke from "../assets/logos/fluke.svg";
import LogoGeokon from "../assets/logos/geokon-logo.png";
import LogoCampbell from "../assets/logos/logo_campbellcsi.png";
import LogoLifeloc from "../assets/logos/logo-lifeloc.png";
import LogoTesto from "../assets/logos/logo_testo.png";
import LogoHoneywell from "../assets/logos/logo_honeywell.svg";
import LogoPulsar from "../assets/logos/logo_pulsar_measurement.webp";
import LogoRaeSpain from "../assets/logos/logo_raespain.png";
import LogoCapelec from "../assets/logos/logo-capelec.webp";
import LogoSonlist from "../assets/logos/solinst-logo.webp";
import ComprobacionElectrica from "../assets/images/fluke_comprobacion_electrica.webp";
import CamarasTermograficas from "../assets/images/camaras_termograficas.webp";
import MedicionDeTemperatura from "../assets/images/medicion_de_temperatura.webp";
const groupedSolutions = [
  {
    id: "instrumentacion-industrial",
    type: "Instrumentación Industrial",
    description:
      "Equipos de medición y análisis para aplicaciones industriales.",
    solutions: [
      {
        brand: "Fluke",
        brandLogo: LogoFluke,
        title: "Comprobación Eléctrica",
        description:
          "Herramientas para la comprobación y diagnóstico de sistemas eléctricos, incluyendo multímetros y pinzas amperimétricas.",
        url: "https://www.fluke.com/es-bo/productos/comprobacion-electrica",
        image: ComprobacionElectrica,
      },
      {
        brand: "Fluke",
        brandLogo: LogoFluke,
        title: "Cámaras Termográficas y Acústicas",
        description:
          "Soluciones avanzadas para la detección de problemas térmicos y acústicos en aplicaciones industriales.",
        url: "https://www.fluke.com/es-bo/productos/camaras-termicas",
        image: CamarasTermograficas,
      },
      {
        brand: "Fluke",
        brandLogo: LogoFluke,
        title: "Medición de Temperatura",
        description:
          "Instrumentos precisos para la medición de temperatura en diversos contextos industriales.",
        url: "https://www.fluke.com/es-bo/productos/medicion-de-temperatura",
        image: MedicionDeTemperatura,
      },
      {
        brand: "Fluke",
        brandLogo: LogoFluke,
        title: "Herramientas de Alineación",
        description:
          "Equipos para la alineación precisa de maquinaria y componentes industriales.",
        url: "https://www.fluke.com/es-bo/productos/alineacion-de-maquinaria",
      },
      {
        brand: "Fluke",
        brandLogo: LogoFluke,
        title: "Comprobadores de Cableado de Redes",
        description:
          "Herramientas para la verificación y diagnóstico de cableados de red, asegurando su correcto funcionamiento.",
        url: "https://www.fluke.com/es-bo/productos/comprobadores-de-redes",
      },
      {
        brand: "Fluke",
        brandLogo: LogoFluke,
        title: "Herramientas de Calibración",
        description:
          "Soluciones para la calibración precisa de instrumentos de medición, garantizando su exactitud y confiabilidad.",
        url: "https://www.fluke.com/es-bo/productos/herramientas-de-calibracion",
      },
      {
        brand: "Fluke Biomedical",
        brandLogo: LogoFlukeBiomedical,
        title: "Servicios de Calibración y Reparación",
        description:
          "Servicios de calibración y reparación para garantizar la precisión y funcionamiento óptimo de equipos biomédicos.",
        url: "https://www.flukebiomedical.com/",
      },
      {
        brand: "Fluke Biomedical",
        brandLogo: LogoFlukeBiomedical,
        title: "Paquetes de Cuidado Fluke Biomedical",
        description:
          "Paquetes de servicio que ofrecen mantenimiento preventivo y cobertura extendida para equipos biomédicos.",
        url: "https://www.flukebiomedical.com/",
      },
      {
        brand: "Fluke Biomedical",
        brandLogo: LogoFlukeBiomedical,
        title: "Servicios de Medición de Radiación",
        description:
          "Servicios especializados en la medición y análisis de radiación para entornos médicos y de investigación.",
        url: "https://www.landauer.com/",
      },
      {
        brand: "Fluke Biomedical",
        brandLogo: LogoFlukeBiomedical,
        title: "Consultoría en Física Médica",
        description:
          "Consultoría en física médica que ofrece asesoramiento experto en protección radiológica y optimización de procesos.",
        url: "https://www.landauer.com/",
      },
      {
        brand: "Fluke Networks",
        brandLogo: LogoFlukeNetworks,
        title: "Comprobadores de Cableado de Redes",
        description:
          "Herramientas para la comprobación y certificación de cableados de red, asegurando su correcto funcionamiento y conformidad con los estándares de la industria.",
        url: "https://www.flukenetworks.com/",
      },
      {
        brand: "Fluke Networks",
        brandLogo: LogoFlukeNetworks,
        title: "Comprobadores de Fibra Óptica",
        description:
          "Instrumentos diseñados para la prueba y diagnóstico de redes de fibra óptica, facilitando la identificación de problemas y la certificación de la instalación.",
        url: "https://www.flukenetworks.com/",
      },
      {
        brand: "Fluke Networks",
        brandLogo: LogoFlukeNetworks,
        title: "Certificación de Cableado",
        description:
          "Soluciones para la certificación de sistemas de cableado, incluyendo cobre y fibra óptica, para cumplir con los estándares de rendimiento.",
        url: "https://www.flukenetworks.com/",
      },
      {
        brand: "Fluke Networks",
        brandLogo: LogoFlukeNetworks,
        title: "OTDR: Reflectómetro de Dominio de Tiempo Óptico",
        description:
          "Herramientas avanzadas para la medición y análisis de redes de fibra óptica, permitiendo la localización precisa de fallos y la verificación de la calidad de la instalación.",
        url: "https://www.flukenetworks.com/",
      },
      {
        brand: "Fluke Networks",
        brandLogo: LogoFlukeNetworks,
        title: "Comprobación de Cables de Categoría 8",
        description:
          "Equipos específicos para la comprobación y certificación de instalaciones de cableado de categoría 8, asegurando el máximo rendimiento de la red.",
        url: "https://www.flukenetworks.com/",
      },
      {
        brand: "Fluke Networks",
        brandLogo: LogoFlukeNetworks,
        title: "Casos Reales",
        description:
          "Análisis detallados de casos reales donde las soluciones de Fluke Networks han sido aplicadas para resolver problemas complejos en la infraestructura de red.",
        url: "https://www.flukenetworks.com/",
      },
      {
        brand: "Testo",
        brandLogo: LogoTesto,
        title: "Tecnología de Calefacción",
        description:
          "Soluciones avanzadas para la medición y regulación en sistemas de calefacción, optimizando su eficiencia y rendimiento.",
        url: "https://www.testo.com/es/aplicaciones/hvacr-heating",
      },
      {
        brand: "Testo",
        brandLogo: LogoTesto,
        title: "Tecnología de Refrigeración",
        description:
          "Instrumentos de precisión para el monitoreo y mantenimiento de sistemas de refrigeración, asegurando su operación óptima.",
        url: "https://www.testo.com/es/aplicaciones/hvacr-refrigeration",
      },
      {
        brand: "Testo",
        brandLogo: LogoTesto,
        title: "Sistemas de Ventilación y Aire Acondicionado",
        description:
          "Herramientas especializadas para la evaluación y gestión de la calidad del aire y sistemas de ventilación.",
        url: "https://www.testo.com/es/aplicaciones/hvacr-ventilation-air-conditioning",
      },
      {
        brand: "Honeywell",
        brandLogo: LogoHoneywell,
        title: "Automatización de Edificios",
        description:
          "Soluciones para la gestión inteligente y eficiente de edificios comerciales e industriales.",
        url: "https://buildingsolutions.honeywell.com/",
      },
      {
        brand: "Honeywell",
        brandLogo: LogoHoneywell,
        title: "Automatización y Productividad",
        description:
          "Tecnologías avanzadas para mejorar la productividad y eficiencia en operaciones industriales.",
        url: "https://safety.honeywell.com/",
      },
      {
        brand: "Honeywell",
        brandLogo: LogoHoneywell,
        title: "Tecnologías de Detección y Seguridad",
        description:
          "Equipos para la detección de gases, protección personal y seguridad en ambientes industriales.",
        url: "https://safety.honeywell.com/",
      },
      {
        brand: "Honeywell",
        brandLogo: LogoHoneywell,
        title: "Adsorbente y Catalizador",
        description:
          "Productos químicos especializados para procesos industriales y de purificación.",
        url: "https://www.honeywell.com/",
      },
      {
        brand: "Honeywell",
        brandLogo: LogoHoneywell,
        title: "Productos Químicos y Materiales Industriales",
        description:
          "Materiales avanzados para diversas aplicaciones industriales y manufactura.",
        url: "https://www.honeywell.com/",
      },
      {
        brand: "Honeywell",
        brandLogo: LogoHoneywell,
        title: "Solsticio®",
        description:
          "Refrigerantes, agentes espumantes y solventes con bajo potencial de calentamiento global.",
        url: "https://www.honeywell.com/",
      },
      {
        brand: "Honeywell",
        brandLogo: LogoHoneywell,
        title: "Investigación Química",
        description:
          "Innovaciones en química para aplicaciones industriales y de seguridad.",
        url: "https://www.honeywell.com/",
      },
      {
        brand: "Pulsar Measurement",
        brandLogo: LogoPulsar,

        title: "Medidores de Fluido",
        description:
          "Instrumentos para la medición precisa del flujo de diversos fluidos en aplicaciones industriales.",
        url: "https://pulsarmeasurement.com/en/products",
      },
      {
        brand: "Pulsar Measurement",
        brandLogo: LogoPulsar,
        title: "Medidores de Nivel",
        description:
          "Soluciones avanzadas para la medición de nivel, aplicables en una amplia gama de industrias y sustancias.",
        url: "https://pulsarmeasurement.com/en/products",
      },
      {
        brand: "Pulsar Measurement",
        brandLogo: LogoPulsar,
        title: "Medidores de Volumen",
        description:
          "Equipos diseñados para proporcionar mediciones de volumen precisas y confiables para el control de procesos.",
        url: "https://pulsarmeasurement.com/en/products",
      },
      {
        brand: "Pulsar Measurement",
        brandLogo: LogoPulsar,
        title: "Control de Bomba",
        description:
          "Sistemas de control de bombas para optimizar el funcionamiento y la eficiencia de sistemas de bombeo.",
        url: "https://pulsarmeasurement.com/en/products",
      },
      {
        brand: "Pulsar Measurement",
        brandLogo: LogoPulsar,
        title: "Manta de Lodos",
        description:
          "Soluciones para el monitoreo de mantas de lodos en tratamientos de aguas residuales y procesos similares.",
        url: "https://pulsarmeasurement.com/en/products",
      },
      {
        brand: "Pulsar Measurement",
        brandLogo: LogoPulsar,
        title: "Protección del Proceso",
        description:
          "Tecnologías destinadas a la protección y seguridad de procesos industriales a través de la monitorización precisa.",
        url: "https://pulsarmeasurement.com/en/products",
      },
    ],
  },
  {
    id: "meteorologia-e-hidrologia",
    type: "Meteorología e Hidrología",
    description: "Monitoreo climático y del agua.",
    solutions: [
      {
        brand: "Campbell Scientific",
        brandLogo: LogoCampbell,

        title: "Soluciones de Monitoreo Meteorológico",
        description:
          "Incluye Instrumentación para Investigación en Agricultura y Suelos, Instrumentación para Calidad del Aire y Polución, Instrumentación Meteorológica Automatizada para Alpinismo, Estaciones Meteorológicas Automáticas (AWS) e Instrumentos Meteorológicos, Meteorología para Aviación, Soluciones ClimaVue 50 + Aspen 10, Investigación Ambiental, Instrumentación para Evapotranspiración e Irrigación Comercial, Estaciones Meteorológicas para Incendios Forestales, Soluciones HygroVue 10 + Aspen 10, Estaciones Meteorológicas de Grado Marino, Soluciones RainVue 10 + Aspen 10, Soluciones RainVue 20 + Aspen 10, Soluciones SnowVue 10 + Aspen 10, Soluciones TempVue 20 y Aspen 10, Soluciones Wintersense SDI-12 + Aspen 10.",
        url: "https://www.campbellsci.com/weather",
      },
      {
        brand: "Campbell Scientific",
        brandLogo: LogoCampbell,
        title: "Soluciones de Monitoreo de Agua",
        description:
          "Incluye Sistemas de Alerta de Inundaciones (ALERT/ALERT2), Instrumentación para Monitoreo de Aguas Superficiales, Calidad del Agua.",
        url: "https://www.campbellsci.com/water",
      },
    ],
  },
  {
    id: "instrumentacion-geotecnica",
    type: "Instrumentación Geotécnica",
    description: "Monitoreo de estructuras y suelos.",
    solutions: [
      {
        brand: "Campbell Scientific",
        brandLogo: LogoCampbell,
        title: "Soluciones de Monitoreo de Infraestructuras",
        description:
          "Incluye Monitoreo de Puentes, Estructuras de Edificios, Construcción, Monitoreo de Presas, Geotécnica, Minería, Petróleo y Gas, Pavimentos y Carreteras, Monitoreo Ferroviario, Sistemas de Información de Meteorología Vial (RWIS), Sísmico, Estabilidad de Taludes, Monitoreo de Salud Estructural, Pruebas de Vehículos.",
        url: "https://www.campbellsci.com/infrastructure",
      },
      {
        brand: "Geokon",
        brandLogo: LogoGeokon,

        title: "Puentes",
        description:
          "Soluciones para el monitoreo estructural y la salud de puentes.",
        url: "https://www.geokon.com/bridges",
      },
      {
        brand: "Geokon",
        brandLogo: LogoGeokon,
        title: "Presas",
        description:
          "Instrumentación para el monitoreo de presas, incluyendo estabilidad y filtraciones.",
        url: "https://www.geokon.com/dams",
      },
      {
        brand: "Geokon",
        brandLogo: LogoGeokon,
        title: "Terraplenes",
        description:
          "Sistemas para el análisis de estabilidad y movimiento en terraplenes.",
        url: "https://www.geokon.com/embankments",
      },
      {
        brand: "Geokon",
        brandLogo: LogoGeokon,
        title: "Fundaciones y Excavaciones",
        description:
          "Monitorización de fundaciones y excavaciones para proyectos de construcción.",
        url: "https://www.geokon.com/foundations-excavations",
      },
      {
        brand: "Geokon",
        brandLogo: LogoGeokon,
        title: "Geogrillas",
        description:
          "Instrumentación para evaluar la integridad y el comportamiento de geogrillas en aplicaciones de refuerzo.",
        url: "https://www.geokon.com/geogrids",
      },
      {
        brand: "Geokon",
        brandLogo: LogoGeokon,
        title: "Aguas Subterráneas",
        description:
          "Soluciones para el monitoreo de niveles y calidad de aguas subterráneas.",
        url: "https://www.geokon.com/groundwater",
      },
      {
        brand: "Geokon",
        brandLogo: LogoGeokon,
        title: "Vertederos",
        description:
          "Sistemas para el monitoreo de condiciones en vertederos, incluyendo estabilidad y lixiviados.",
        url: "https://www.geokon.com/landfills",
      },
      {
        brand: "Geokon",
        brandLogo: LogoGeokon,
        title: "Minas",
        description:
          "Instrumentación para la seguridad y monitoreo en minas, abarcando desde estabilidad de taludes hasta ventilación.",
        url: "https://www.geokon.com/mines",
      },
      {
        brand: "Geokon",
        brandLogo: LogoGeokon,
        title: "Repositorios de Residuos Nucleares",
        description:
          "Soluciones específicas para el monitoreo de repositorios de residuos nucleares y su impacto ambiental.",
        url: "https://www.geokon.com/nuclear-waste",
      },
      {
        brand: "Geokon",
        brandLogo: LogoGeokon,
        title: "Pilotes",
        description:
          "Tecnologías para el análisis de la carga y salud estructural de pilotes en construcciones.",
        url: "https://www.geokon.com/piles",
      },
      {
        brand: "Geokon",
        brandLogo: LogoGeokon,
        title: "Tuberías",
        description:
          "Sistemas para la inspección y monitoreo de la integridad de tuberías, incluyendo detección de fugas.",
        url: "https://www.geokon.com/pipelines",
      },
      {
        brand: "Geokon",
        brandLogo: LogoGeokon,
        title: "Estabilidad de Taludes",
        description:
          "Instrumentación para evaluar la estabilidad de taludes y prevenir deslizamientos.",
        url: "https://www.geokon.com/slope-stability",
      },
      {
        brand: "Geokon",
        brandLogo: LogoGeokon,
        title: "Túneles",
        description:
          "Monitorización de túneles para garantizar su seguridad operacional y estructural.",
        url: "https://www.geokon.com/tunnels",
      },
      {
        brand: "Geokon",
        brandLogo: LogoGeokon,
        title: "Turbinas Eólicas",
        description:
          "Soluciones para el monitoreo de la estructura y cimentación de turbinas eólicas.",
        url: "https://www.geokon.com/wind-turbines",
      },
      {
        brand: "Testo",
        brandLogo: LogoTesto,
        title: "Industria",
        description:
          "Equipos de medición y análisis para aplicaciones industriales, incluyendo monitoreo ambiental y de procesos.",
        url: "https://www.testo.com/es/productos",
      },
    ],
  },
  {
    id: "seguridad-industrial",
    type: "Seguridad Industrial",
    description: "Equipos para la seguridad laboral.",
    solutions: [
      {
        brand: "Lifeloc",
        brandLogo: LogoLifeloc,

        title: "Probadores de Alcohol en el Aliento",
        description:
          "Dispositivos avanzados para la detección rápida y precisa de alcohol en el aliento, esenciales para la seguridad en el lugar de trabajo.",
        url: "https://www.lifeloc.com/",
      },
      {
        brand: "Lifeloc",
        brandLogo: LogoLifeloc,
        title: "Equipo de Calibración",
        description:
          "Kits y dispositivos para la calibración de equipos de medición de alcohol, asegurando su precisión y fiabilidad.",
        url: "https://www.lifeloc.com/",
      },
      {
        brand: "Lifeloc",
        brandLogo: LogoLifeloc,
        title: "Suministros de Punto",
        description:
          "Accesorios y suministros para el uso con dispositivos de medición de alcohol, incluyendo boquillas desechables y soluciones de calibración.",
        url: "https://www.lifeloc.com/",
      },
      {
        brand: "Lifeloc",
        brandLogo: LogoLifeloc,
        title: "Boquillas",
        description:
          "Boquillas desechables para probadores de alcohol, garantizando higiene y precisión en cada prueba.",
        url: "https://www.lifeloc.com/",
      },
      {
        brand: "Lifeloc",
        brandLogo: LogoLifeloc,
        title: "Productos de Pantalla de Drogas",
        description:
          "Soluciones para el screening de drogas, complementando los esfuerzos de seguridad y sobriedad en ambientes laborales.",
        url: "https://www.lifeloc.com/",
      },
      {
        brand: "Testo",
        brandLogo: LogoTesto,
        title: "Seguridad Alimenticia",
        description:
          "Soluciones para la verificación de la seguridad y calidad alimentaria, desde la producción hasta el punto de venta.",
        url: "https://www.testo.com/es/aplicaciones/foodsafety",
      },
      {
        brand: "Testo",
        brandLogo: LogoTesto,
        title: "Farmacéutica",
        description:
          "Instrumentación para el control de calidad y monitoreo ambiental en la industria farmacéutica.",
        url: "https://www.testo.com/es/aplicaciones/pharma",
      },
      {
        brand: "Testo",
        brandLogo: LogoTesto,
        title: "Industria",
        description:
          "Equipos de medición y análisis específicos para aplicaciones industriales diversas.",
        url: "https://www.testo.com/es/aplicaciones/industry",
      },
      {
        brand: "Honeywell",
        brandLogo: LogoHoneywell,
        title: "Seguridad",
        description:
          "Soluciones integrales para la seguridad en el trabajo, incluyendo equipos de protección personal y sistemas de detección de gases.",
        url: "https://safety.honeywell.com/",
      },
      {
        brand: "Honeywell",
        brandLogo: LogoHoneywell,
        title: "Forja Honeywell",
        description:
          "Plataforma de software para la optimización de la seguridad y productividad en operaciones industriales.",
        url: "https://www.honeywell.com/",
      },
    ],
  },
  {
    id: "energia-solar",
    type: "Energía Solar",
    description: "Soluciones de energía renovable.",
    solutions: [
      {
        brand: "Campbell Scientific",
        brandLogo: LogoCampbell,
        title: "Soluciones de Monitoreo de Energía",
        description:
          "Incluye soluciones para Energía Geotérmica, Hidroenergía, Petróleo y Gas, Energía Solar, Servicios Públicos y Energía, Energía Eólica.",
        url: "https://www.campbellsci.com/energy",
      },
    ],
  },
  {
    id: "inspeccion-tecnica-vehicular",
    type: "Inspección Técnica Vehicular",
    description: "Equipos de diagnóstico para vehículos.",
    solutions: [
      {
        brand: "Capelec",
        brandLogo: LogoCapelec,

        title: "Analizador de Gases + Opacímetro",
        description:
          "Instrumentos combinados para la medición de emisiones de gases y opacidad del humo de escape en vehículos.",
        url: "https://www.capelec.com/es/equipements/gamme/analizador-de-gases-opacimetro",
      },
      {
        brand: "Capelec",
        brandLogo: LogoCapelec,
        title: "Analizadores de Gases",
        description:
          "Equipos especializados en la evaluación de la composición de gases emitidos por vehículos para asegurar el cumplimiento ambiental.",
        url: "https://www.capelec.com/es/equipements/gamme/analizador-de-gases-opacimetro",
      },
      {
        brand: "Capelec",
        brandLogo: LogoCapelec,
        title: "Opacímetros",
        description:
          "Dispositivos diseñados para medir la opacidad o densidad del humo producido por motores diésel.",
        url: "https://www.capelec.com/es/equipements/gamme/opacimetros",
      },
      {
        brand: "Capelec",
        brandLogo: LogoCapelec,
        title: "Tacómetro",
        description:
          "Herramientas para medir la velocidad del motor, crucial para diagnósticos de rendimiento y mantenimiento.",
        url: "https://www.capelec.com/es/equipements/gamme/tacometro",
      },
      {
        brand: "Capelec",
        brandLogo: LogoCapelec,
        title: "Escáneres EOBD",
        description:
          "Sistemas de diagnóstico a bordo para la identificación rápida de problemas y mantenimiento preventivo de vehículos.",
        url: "https://www.capelec.com/es/equipements/gamme/escaneres-eobd",
      },
      {
        brand: "Capelec",
        brandLogo: LogoCapelec,
        title: "Medida de Partículas",
        description:
          "Instrumentos para la detección y cuantificación de partículas sólidas emitidas por vehículos, contribuyendo a la evaluación de su impacto ambiental.",
        url: "https://www.capelec.com/es/equipements/gamme/medida-de-particulas",
      },
    ],
  },
];

export default groupedSolutions;
